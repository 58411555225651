<template>
    <div v-if="selectedItems && selectedItems.length" class="selected-items">
        <div class="selected-items-list">
            <div v-for="(item, index) of selectedItems" :key="`selected-filter-item-${index}`" class="selected-item">
                <CountryFlag
                    v-if="item.icon"
                    :iso-code="item.icon && item.icon.isoCode"
                    :img-src="item.icon && item.icon.src"
                    :img-alt-src="item.icon && item.icon.altSrc"
                    class="selected-item-icon rounded-flag icon-size-small"
                    is-squared
                />
                <div class="selected-item-label">
                    <span v-if="item.prefix" class="selected-item-label-prefix">{{ item.prefix }}</span> {{ item.label }}
                </div>
                <div class="selected-item-action" @click="removeItem(item)">
                    <SvgIcon class="action-icon" icon-id="icon-cross-circled-fill" vertical-align="top" />
                </div>
            </div>
            <div v-if="selectedItems.length > 1" class="clear-all" @click="clearAll()">
                {{ $t('ui.eventFilters.clearAll') }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { helper } from '@/modules/core';
import { EVENTS_SORTING_ACCEPTABLE_MAP } from '@/modules/sport/const/events-const';
import CountryFlag from '@/modules/platform/components/CountryFlag.vue';

export default {
    name: 'FilterSelectedItemList',
    components: { CountryFlag },
    props: {
        queryMarket: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    data() {
        return {
            selectedCompetitions: [],
            sorting: null,
        };
    },
    computed: {
        ...mapState({
            tournaments: 'currentTournaments',
            countries: (state) => state.countries,
        }),
        allCountriesCompetitions() {
            return helper.removeArrayDuplicates(
                Array.from(this.countries)
                    .map(({ competitions }) => competitions.map((competition) => competition.id))
                    .flat()
            );
        },
        tournamentsWithoutCountry() {
            return this.tournaments.filter((i) => !this.allCountriesCompetitions.includes(i.id));
        },
        selectedItems() {
            const items = [];
            const defaultSorting = EVENTS_SORTING_ACCEPTABLE_MAP.competitionPriority_DESC;
            if (this.sorting && this.sorting !== defaultSorting) {
                items.push(this.sortingToItem());
            }
            Array.from(this.countries).forEach((country) => {
                const allCountryCompetitionsSelected = country.competitions.every((i) => this.selectedCompetitions.includes(i.id));
                if (allCountryCompetitionsSelected) {
                    items.push(this.countryToItem({ country }));
                } else {
                    items.push(...this.countryToItems({ country }));
                }
            });

            if (this.tournamentsWithoutCountry.length) {
                items.push(...this.tournamentsWithoutCountry.map((tournament) => this.tournamentToItem({ tournament })));
            }
            if (this.queryMarket && this.queryMarket.index > 0) {
                items.push(this.marketToItem({ market: this.queryMarket }));
            }
            this.$emit('selectedItemsChanged', { items });
            return items;
        },
    },
    watch: {
        '$route.query': {
            immediate: true,
            deep: true,
            handler({ competitions, sorting }) {
                let payloadCompetitions = [];
                if (competitions) {
                    const competitionsArr = Array.isArray(competitions) ? competitions : competitions.split(',');
                    payloadCompetitions = helper.removeArrayDuplicates(payloadCompetitions, competitionsArr);
                }
                this.sorting = sorting || null;

                this.selectedCompetitions = payloadCompetitions;
            },
        },
    },
    methods: {
        removeItem({ competitions, isMarket, isSorting }) {
            this.$emit('onRemove', { competitions, isMarket, isSorting });
        },
        convertToItem({ label = '', competitions = [], isMarket = false, icon = null }) {
            return {
                label,
                competitions,
                icon,
                isMarket,
            };
        },
        sortingToItem() {
            return {
                prefix: `${this.$t('ui.eventSorting.sortedBy')}:`,
                label: `${this.$t(`ui.eventSorting.${EVENTS_SORTING_ACCEPTABLE_MAP[this.sorting]}`)}`,
                isSorting: true,
            };
        },
        countryToItem({ country }) {
            const {
                region: { name },
                competitions,
                icon,
            } = country;
            const competitionsIds = competitions.map((i) => i.id);
            return this.convertToItem({ label: name, competitions: competitionsIds, icon });
        },
        countryToItems({ country }) {
            return country.competitions
                .filter((i) => this.selectedCompetitions.includes(i.id))
                .map((i) => {
                    return this.convertToItem({ label: i.name, competitions: [i.id], icon: i.icon });
                });
        },
        tournamentToItem({ tournament }) {
            const { name, icon, id } = tournament;
            return this.convertToItem({ label: name, competitions: [id], icon });
        },
        marketToItem({ market }) {
            const { label } = market;
            return this.convertToItem({ label, isMarket: true });
        },
        clearAll() {
            const competitions = this.selectedItems.map((item) => item.competitions).flat();
            this.removeItem({ competitions, isMarket: true, isSorting: true });
        },
    },
};
</script>

<style lang="scss" scoped>
.selected-items {
    display: flex;
    position: relative;
    align-items: center;
    padding: 5px 6px;
    background: $white-bg;

    &-list {
        display: flex;
        overflow: auto;
        flex-grow: 1;
        align-items: center;
    }
}
.selected-item {
    display: flex;
    padding: 7px 8px;
    border-radius: 40px;
    background-color: #d9edb2;
    align-items: center;
    margin: 5px 6px;
    flex-shrink: 0;

    &-icon {
        margin-right: 4px;
    }

    &-label {
        font-size: 14px;
        line-height: 18px;
        margin-right: 6px;

        &-prefix {
            @extend %body-normal-font-500;
        }
    }

    &-action {
        fill: #353b40;
        line-height: 12px;
    }
}

.action-icon {
    width: 12px;
    height: 12px;
    cursor: pointer;
}
.clear-all {
    @extend %body-normal-font-500;
    flex-shrink: 0;
    white-space: nowrap;
    padding: 6px;
    cursor: pointer;
    text-decoration: underline;
    display: flex;
    align-items: center;
}
</style>
