<template>
    <div>
        <!-- CashOut Message-->
        <!-- For cashOut v1 Compatibility `requestOfferError.message` is needed inside `if` -->
        <div class="request-offer" :class="getRequestOfferWrapperClass">
            <div class="offer-message" :class="{ 'row-cell align-middle': isPresto }">
                <p v-if="isNetworkError">
                    {{ $t('ui.common.error.networkError') }}
                </p>
                <Spinner v-else-if="showCashoutInProgress && !isCashOutRefreshing" visible class="justify-center" type="bp-spinner">
                    {{ offerInProgressMessage }}
                </Spinner>
                <div v-else-if="showReloadCashoutStatus">
                    {{ offerInProgressMessage }}
                    <Currency
                        :contrast="false"
                        class="offer-amount"
                        :amount="cashOutOffer"
                        :format="settings.currency"
                        data-test-id="cashoutOffer"
                    />
                </div>
                <p v-else-if="isCashOutLocked">
                    <renderer :input="$t('ui.cashout.cashoutLocked')" />
                </p>
                <p v-else-if="requestOfferError.message">
                    <template v-if="isGenericError">
                        {{ $t('ui.common.error.genericError') }}
                    </template>
                    <template v-else>
                        <renderer :input="requestOfferError.message" />
                    </template>
                </p>
                <div v-else-if="!isNetworkError && isCashoutOfferLoadingOrReady && isConfirmCashOutReady" class="cashout-offer">
                    <p>{{ $t('ui.cashout.cashoutOffer') }}</p>
                    <Currency
                        class="offer-amount"
                        data-test-id="cashoutOffer"
                        :contrast="false"
                        :amount="cashOutOffer"
                        :format="settings.currency"
                        :is-loading="isCashOutOfferCurrencyLoading"
                    />
                </div>
                <p v-else>
                    <SvgIcon class="cashout-icon icon-size-medium" icon-id="icon-cashoutable" />
                    {{ $t('ui.cashout.cashout') }}
                </p>
            </div>

            <!-- CashOut Actions-->
            <div :class="{ 'row-cell align-middle right': isPresto }">
                <button v-if="showReloadCashoutStatus" class="button button-primary offer-button" @click="reloadCashoutStatus()">
                    {{ $t('ui.cashout.confirmationReload') }}
                </button>
                <PromptAction
                    v-else-if="showConfirmCashout"
                    :disabled="confirmOfferIsLoading || !!pollingCounter"
                    :class="{ 'offer-button offer-button-prompt-action': $mq.isXSmall }"
                    :left="{
                        text: $t('ui.cashout.acceptOffer'),
                        color: 'primary',
                        buttonCssClass: isPresto ? 'button-very-small button-presto row-cell' : '',
                        ...promptActionDisabledState.leftAction,
                    }"
                    :right="{
                        isLoading: isCashOutRefreshing,
                        icon: isOldCashOut ? 'icon-close' : 'icon-reload',
                        iconCssClass: isOldCashOut ? 'icon-size-small icon-white-color' : 'icon-size-small',
                        buttonCssClass: promptRightBtnClasses,
                        ...promptActionDisabledState.rightAction,
                    }"
                    @leftResolvePrompt="promptLeftBtnAction"
                    @rightResolvePrompt="promptRightBtnAction"
                />
                <Button v-else :disabled="disableRequestCashout" class="button button-accent offer-button" @click="requestOffer()">
                    {{ requestCashoutButtonText }}
                </Button>
            </div>
        </div>

        <!-- CashOut Accept Modal -->
        <Modal name="cashout-modal" class="cashout-modal" :options="{ overlay: true }" width="312px" :fixed="true" @close="closeModal">
            <div class="title">{{ $t('ui.cashout.cashOutNowModalTitle') }}</div>
            <p class="description">
                <renderer
                    :input="
                        $t('ui.cashout.cashOutNowModalDescription', {
                            newOffer: numberFormat(cashOutOffer),
                            oldOffer: acceptableOffer,
                            currency: currencySymbol,
                        })
                    "
                />
            </p>
            <Button class="button button-primary button-full button-accept-cashout" @click="confirmOffer">
                {{ $t('ui.cashout.acceptOffer') }}
            </Button>
            <button class="button button-accent button-full" @click="closeModal">
                {{ $t('ui.cashout.cancelOffer') }}
            </button>
        </Modal>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { action as sportAction, getter as sportGetter, mutation as sportMutation } from '@/modules/sport/store/const';

import { deviceType } from '@/modules/core';
import { getter as coreGetter } from '@/modules/core/store/const';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { Currency, PromptAction } from '@/modules/core/components';
import Button from '@/components/Button.vue';
import { getObjectField, numberFormat } from '@/modules/core/utils/helper';

const CASHOUT_MODAL_DISPLAY_DURATION = 60000;

export default {
    name: 'BaseCashOut',
    components: { PromptAction, Button, Currency },
    props: {
        isOldCashOut: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            cashoutTimeout: null,
            cashOutModalTimeOutId: null,
        };
    },
    computed: {
        ...mapState({
            betslip: (state) => state.sport.myBets.betslip,
            isNetworkError: (state) => state.sport.myBets.offerError.isNetworkError,
            pollingCounter: (state) => state.sport.myBets.offerStatus.pollingCounter,
            offerStatus: (state) => state.sport.myBets.offerStatus,
            cashoutStatusId: (state) => state.sport.myBets.offerStatus.statusId,
            currencySymbol: (state) => state.platform.settings.currency.symbol,
        }),
        ...mapGetters({
            isLoading: coreGetter.IS_LOADING,
            settings: platformGetter.GET_SETTINGS,
            autoCashOut: sportGetter.GET_AUTO_CASHOUT_OFFER,
            requestOfferError: sportGetter.GET_CASHOUT_OFFER_ERRORS,
            isConfirmCashOutReady: sportGetter.GET_IS_CONFIRM_CASHOUT_READY,
            isCashOutRefreshing: sportGetter.GET_IS_CASHOUT_REFRESHING,
            cashOutAvailabilityTime: platformGetter.GET_CASHOUT_AVAILABILITY_TIME,
            offerPolling: sportGetter.GET_CASHOUT_OFFER_POLLING,
        }),
        isCashOutOfferCurrencyLoading() {
            return !this.isOldCashOut && (this.showCashoutInProgress || this.requestOfferIsLoading);
        },
        acceptableOffer() {
            return numberFormat(getObjectField(this.autoCashOut, 'acceptableOffer', 0));
        },
        promptRightBtnClasses() {
            return this.isPresto ? 'button-very-small row-cell' : this.isOldCashOut ? 'button-small' : 'button-small button-refresh';
        },
        cashOutOffer() {
            return (
                Number(getObjectField(this.betslip, 'cashout.payout.net', 0)) +
                Number(getObjectField(this.betslip, 'cashout.payout.whtCompensation.net', 0))
            );
        },
        isCashoutOfferValid() {
            return !!(this.betslip?.cashout?.offerId && this.cashOutOffer);
        },
        showConfirmCashout() {
            return this.isCashoutOfferLoadingOrReady && !this.requestOfferError.message && this.isConfirmCashOutReady;
        },
        isCashoutOfferLoadingOrReady() {
            return this.isCashOutOfferCurrencyLoading || this.isCashoutOfferValid;
        },
        disableRequestCashout() {
            return this.requestOfferError.dead || this.requestOfferIsLoading;
        },
        isGenericError() {
            return this.requestOfferError.message && this.requestOfferError.isGenericError;
        },
        showReloadCashoutStatus() {
            return this.isPresto && this.pollingCounter;
        },
        requestOfferIsLoading() {
            return this.isLoading(sportAction.REQUEST_CASHOUT_OFFER);
        },
        autoCashoutRequestOfferIsLoading() {
            return this.isLoading(sportAction.GET_BETSLIP_AUTO_CASHOUT_OFFER);
        },
        confirmOfferIsLoading() {
            return this.isLoading(sportAction.CONFIRM_CASHOUT_OFFER);
        },
        isCashOutLocked() {
            return this.offerPolling.pollingEnabled && !this.requestOfferError.dead;
        },
        promptActionDisabledState() {
            return {
                leftAction: (!this.isOldCashOut && { disabled: this.isCashOutRefreshing || this.showCashoutInProgress }) || {},
                rightAction: (!this.isOldCashOut && { disabled: !this.isCashOutRefreshing && this.showCashoutInProgress }) || {},
            };
        },
        showCashoutInProgress() {
            return (
                (this.requestOfferIsLoading ||
                    this.autoCashoutRequestOfferIsLoading ||
                    this.confirmOfferIsLoading ||
                    this.pollingCounter) &&
                !this.showReloadCashoutStatus &&
                !this.isNetworkError
            );
        },
        offerInProgressMessage() {
            if (this.pollingCounter && !this.isPresto) {
                return this.$t('ui.cashout.confirmationStatusProgress', { seconds: this.pollingCounter });
            }
            if (this.showReloadCashoutStatus) {
                return this.$t('ui.cashout.confirmationStatusCheckReload', { seconds: this.pollingCounter });
            }
            return this.$t('ui.cashout.processingOffer');
        },
        requestCashoutButtonText() {
            if (!this.requestOfferError.dead) {
                return this.requestOfferError.isLockedError ? this.$t(`ui.common.tryAgain`) : this.$t(`ui.cashout.requestOffer`);
            }
            return this.$t('ui.cashout.buttonCashoutUnavailable');
        },
        getRequestOfferWrapperClass() {
            const isError = this.requestOfferError.message || this.requestOfferError.isNetworkError;
            return [
                isError &&
                    !this.requestOfferError.isLockedError &&
                    (!this.requestOfferError.dead || this.requestOfferError.isNetworkError ? 'warn' : 'error'),
                this.isPresto && 'table',
            ];
        },
    },
    watch: {
        'betslip.cashout'(cashout) {
            const timerNotStarted = !this.cashoutTimeout && this.cashOutAvailabilityTime;
            const timerCanStart = this.isConfirmCashOutReady || !cashout;
            if (timerNotStarted && timerCanStart) {
                const secondsLeft = this.cashOutAvailabilityTime * 1000;
                clearTimeout(this.cashoutTimeout);
                this.cashoutTimeout = setTimeout(() => {
                    this.cashoutTimeout = null;
                    const { id, betslipType } = this.betslip;
                    this.getSingleBetslip({ id, betslipType }).then(() => {
                        this.setIsConfirmCashOutReady(true);
                    });
                }, secondsLeft);
            }
        },
        offerStatus({ confirmed }) {
            if (confirmed) {
                this.refreshBetslip();
            }
        },
        requestOfferError(requestOfferError) {
            if (this.isOldCashOut && requestOfferError.refresh) {
                this.refreshBetslip();
            }
        },
    },
    beforeDestroy() {
        clearTimeout(this.cashOutModalTimeOutId);
        clearTimeout(this.cashoutTimeout);
    },
    methods: {
        numberFormat,
        ...mapActions({
            requestCashoutOffer: sportAction.REQUEST_CASHOUT_OFFER,
            getSingleBetslip: sportAction.GET_SINGLE_BETSLIP,
            checkCashoutOfferStatus: sportAction.CHECK_CASHOUT_OFFER_STATUS,
            confirmCashoutOffer: sportAction.CONFIRM_CASHOUT_OFFER,
            getBetslipAutoCashoutOffer: sportAction.GET_BETSLIP_AUTO_CASHOUT_OFFER,
        }),
        ...mapMutations({
            setIsConfirmCashOutReady: sportMutation.SET_IS_CONFIRM_CASHOUT_READY,
            setIsCashOutRefreshing: sportMutation.SET_IS_CASHOUT_REFRESHING,
        }),
        cancelOffer() {
            this.trackCashOutOfferRequest({ click: 'cancel' });
            this.refreshBetslip();
        },
        promptLeftBtnAction() {
            return this.isOldCashOut ? this.confirmOffer() : this.acceptOffer();
        },
        promptRightBtnAction() {
            return this.isOldCashOut ? this.cancelOffer() : this.refreshOfferRequest();
        },
        closeModal() {
            clearTimeout(this.cashOutModalTimeOutId);
            this.$modal.hide('cashout-modal');
        },
        refreshBetslip() {
            clearTimeout(this.cashoutTimeout);
            this.cashoutTimeout = null;
            const { id, betslipType } = this.betslip;
            this.getSingleBetslip({ id, betslipType });
        },
        reloadCashoutStatus() {
            this.checkCashoutOfferStatus({
                statusId: this.cashoutStatusId,
                betslipId: this.betslip,
                amount: this.cashOutOffer,
            });
        },
        refreshOfferRequest() {
            if (this.isCashOutRefreshing) return;
            this.requestOffer(true);
            this.trackCashOutOfferRequest({ click: 'refresh' });
        },
        confirmOffer() {
            this.trackCashOutOfferRequest({ click: 'confirm' });
            clearTimeout(this.cashoutTimeout);
            this.confirmCashoutOffer({
                betslip: this.betslip,
                offerId: this.betslip.cashout.offerId,
                amountNet: this.cashOutOffer,
            });
            if (!this.isOldCashOut) {
                this.trackCashOutOfferRequest({ event: 'auto_cashout_offer', action: 'accept_offer_when_auto_cashout_already_set' });
            }
        },
        acceptOffer() {
            if (this.autoCashOut && this.autoCashOut.enabled) {
                this.$modal.show('cashout-modal');
                this.cashOutModalTimeOutId = setTimeout(() => {
                    this.$modal.hide('cashout-modal');
                }, CASHOUT_MODAL_DISPLAY_DURATION);
            } else {
                this.confirmOffer();
            }
        },
        sendOfferRequest() {
            this.requestCashoutOffer({
                betslipId: this.betslip.id,
                amount: this.betslip.stake,
            }).then(() => {
                this.setIsCashOutRefreshing(false);
            });
        },
        requestOffer(isCashOutRefreshing = false) {
            this.setIsConfirmCashOutReady(true);
            this.trackCashOutOfferRequest({ click: this.requestOfferError.isLockedError ? 'request-again' : 'request' });

            if (!this.isOldCashOut) {
                this.setIsCashOutRefreshing(isCashOutRefreshing);

                if (!isCashOutRefreshing) {
                    // TODO: https://aliengain.atlassian.net/browse/BP-31825 no need to make extra request to auto cashout endpoint
                    this.getBetslipAutoCashoutOffer(this.betslip).then(() => {
                        if (this.betslip) this.sendOfferRequest();
                    });
                    return;
                }
            }

            this.sendOfferRequest();
        },
        trackCashOutOfferRequest(extraProps) {
            this.$gtm.query({
                event: 'cashout_offer',
                userId: getObjectField(this.user, 'userUuid'),
                betslipId: this.betslip.id,
                ...extraProps,
            });
        },
    },
};
</script>

<style scoped lang="scss">
.request-offer {
    @extend %body-normal-font-400;
    align-items: center;
    display: flex;
    justify-content: space-between;

    .offer-amount {
        height: 17px;
    }

    .offer-message {
        flex: 1;
        font-weight: normal;
        margin-right: 10px;

        p {
            @extend %body-normal-font-500;
        }

        .spinner {
            line-height: initial;
        }

        .cashout-icon {
            fill: $cashout-offer-icon-color;
            margin: -3px 8px 0;
        }

        @include only_mini_feature_phone {
            display: block;
        }
    }
    .offer-amount {
        color: $cashout-offer-amount-color;
        font-weight: bold;
    }
    .offer-button {
        @extend %body-normal-font-700;
        word-break: break-word;
        flex-basis: content;

        @include only_mini_feature_phone {
            margin-top: 8px;
        }
    }

    .offer-button-prompt-action {
        flex: none;
    }
    @include only_mini {
        align-items: flex-start;
        flex-direction: column;

        .offer-button {
            width: 100%;
        }

        ::v-deep .button-media {
            display: block;
            width: 14px;
            height: 14px;
        }
        ::v-deep .button-media svg {
            position: relative;
            top: -5px;
        }
    }
    ::v-deep .button-presto {
        width: 77%;

        @include oldschool {
            width: 100%;
        }
    }
}

// modal
.cashout-modal {
    .title {
        @extend %h4-font-700;
        text-align: center;
        color: $grey-text;
        margin-bottom: 8px;
    }

    .description {
        @extend %body-normal-font-400;
        text-align: center;
        color: $grey-text;
        margin-bottom: 24px;
    }

    .button-accept-cashout {
        margin-bottom: 8px;
    }
}

.table {
    display: table;
}
</style>
