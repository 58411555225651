export const MAXIMUM_PAGE_SIZE = 100;
export const strapiMenus = {
    LEFT_MENU: 'leftMenu',
    HEADER_MENU: 'headerMenu',
    TOP_MENU: 'topMenu',
    BOTTOM_MENU: 'bottomMenu',
    FOOTER_MENU: 'footerMenu',
    ACCOUNT_MENU: 'accountMenu',
    PRESTO_MENU: 'prestoMenu',
};

const strapiMenusConfig = Object.values(strapiMenus).reduce((acc, menu) => {
    return {
        ...acc,
        [menu]: {
            populate: ['links.page.links', 'breakpoints', 'visibility', 'links.platformVisibility'],
        },
    };
}, {});

export const strapiPopulateFields = {
    PAGES: {
        content: {
            populate: [
                'QuickLinks.page.links',
                'breakpoints',
                'tab.page.links',
                'page.links',
                'align',
                'visibility',
                'icon',
                'articleTop',
                'articleBottom',
                'image',
                'imageFormats',
                'imageFormats.image',
                'questions',
                'settings',
                'deposit_option.page.links',
                'deposit_option.additionalData.badgeForDepositList',
                'deposit_option.additionalData.badgeForDepositComponent',
                'deposit_option.icon',
                'bannerCollectionItems.schedule',
                'bannerCollectionItems.banner.image',
                'bannerCollectionItems.banner.schedule',
                'bannerCollectionItems.banner.page.links',
                'bannerCollectionItems.banner.imageFormats',
                'bannerCollectionItems.banner.imageFormats.image',
                'bannerCollectionItems.banner.platformVisibility',
                'headline',
                'sportItems',
                'casinoCat.id',
                'casinoCat.name',
                'casinoCat.iconId',
                'columnsByBreakpoints',
            ],
        },
        childPages: {
            populate: ['links', 'pinToTop', 'jurisdictions', 'forPreview.image'],
        },
        parentPage: {
            populate: ['links', 'parentPage.links', 'parentPage.parentPage.links'],
        },
        tags: {
            populate: ['pages.links', 'pages.forPreview.image', 'pages.jurisdictions'],
        },
        links: true,
        seo: {
            populate: ['image'],
        },
        localizations: true,
        forPreview: true,
    },
    CONFIG: {
        ...strapiMenusConfig,
        brandPreferences: {
            populate: ['casinoType'],
        },
        depositOptions: {
            populate: [
                'icon',
                'description',
                'page.links',
                'additionalData.badgeForDepositList',
                'additionalData.badgeForDepositComponent',
            ],
        },
        localizations: {
            fields: ['locale'],
        },
        globalConfig: {
            populate: ['competitionIcons.competitionIds', 'competitionIcons.icon'],
        },
        bigWin: {
            populate: ['seoImage', 'tiers', 'tiers.trophyImg', 'tiers.backgroundImg', 'tiers.backgroundImgMobile'],
        },
        ageLimitRegulationContent: {
            populate: ['icon'],
        },
        sidebarDisabledPages: {
            populate: ['path'],
        },
        activeBadges: {
            fields: ['text', 'color', 'identifier'],
        },
        splashPageConfiguration: {
            populate: ['backgroundImage', 'logo'],
        },
    },
};

export const strapiPageSlots = {
    FORGOT_FOOTER_SLOT_NAME: 'FORGOT_FOOTER_COMPONENT',
    USSD_MODAL_SLOT_NAME: 'USSD_MODAL_COMPONENT',
};
