<template>
    <img
        v-if="src && !isNotLoaded"
        v-show="isLoaded"
        class="image-icon"
        :src="getSrc(src)"
        @load="isLoaded = true"
        @error="isNotLoaded = true"
    />
    <img v-else-if="altSrc && isNotLoaded" v-show="isLoaded" class="image-icon" :src="getSrc(altSrc)" @load="isLoaded = true" />
</template>

<script>
import { helper } from '@/modules/core';

export default {
    name: 'ImageIcon',
    props: {
        src: {
            type: String,
            required: true,
        },
        altSrc: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            isLoaded: null,
            isNotLoaded: null,
        };
    },
    methods: {
        getSrc(src) {
            const srcDoesntStartsWithSlash = /^[^/]/.test(src);
            return `${helper.isInternalLink(src) && srcDoesntStartsWithSlash ? '/' : ''}${src}`;
        },
    },
};
</script>

<style scoped lang="scss">
img {
    width: 32px;
    height: 32px;
    vertical-align: middle;

    &.icon-status {
        width: 14px;
        height: 14px;
        min-width: 14px;
        transform: translateY(-6%);
        fill: $icon-primary;
    }

    @extend %icon-sizes;

    @include only_mini {
        @include skip_element;
    }
}
</style>
