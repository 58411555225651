var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.countryMenus),function(menu,countryIndex){return _c('ExpansionPanel',{key:((menu.slug) + "-" + countryIndex),attrs:{"data-test-id":("menu-" + (menu.name)),"title":_vm.$t(("ui.lMenu." + (menu.name))),"control":!_vm.isFeaturePresto,"expanded":true,"is-dark":_vm.isDark}},[_vm._t("default",function(){return [_vm._l((menu.data),function(country,menuIndex){return [_c('MenuItem',{key:((menu.slug) + "-country-" + (country.region.id) + "-" + menuIndex),staticClass:"sub-menu",attrs:{"is-presto":_vm.isFeaturePresto,"data-test-id":("country-menu-" + (country.region.id)),"underline":_vm.isFeaturePresto,"selected":country.isActive,"is-dark":_vm.isDark,"item":{
                        text: country.region.name,
                        control: {
                            text: country.eventCount,
                            icon: _vm.isExpandedCountry(country.region.id) ? 'icon-arrow-up' : 'arrow_down',
                        },
                    }},on:{"onIsLeftSideClick":function($event){return _vm.handleMenuSideClick(
                            $event,
                            country.region.id,
                            $event ? ("All " + (country.region.name)) : country.region.name,
                            $event ? ((menu.slug) + "_country_nav") : ((menu.slug) + "_country_nav_expand"),
                            !_vm.isFeaturePresto
                        )},"onItemClick":function($event){return _vm.toggleCountry(country.region.id, country.region.name, ((menu.slug) + "_country_nav_expand"), _vm.isFeaturePresto)}}},[_c('template',{slot:"icon"},[_c('CountryFlag',{staticClass:"rounded-flag icon-size-large icon-flag",attrs:{"iso-code":country.icon.isoCode,"img-src":country.icon.src,"img-alt-src":country.icon.altSrc,"is-squared":""}})],1),_c('template',{slot:"content"},[(country.competitions)?_c('ExpansionPanel',{attrs:{"is-dark":_vm.isDark,"expanded":_vm.isExpandedCountry(country.region.id)}},[(!_vm.isFeaturePresto)?_c('MenuItem',{staticClass:"sub-menu-item",attrs:{"data-test-id":("country-" + (country.region.id) + "-all"),"selected":country.isSelected,"link":{ name: _vm.routeCountryName, params: { id: country.region.id } },"item":{
                                    text: _vm.$t('menus.all'),
                                    control: { text: country.eventCount, iconDisabled: true },
                                },"is-dark":_vm.isDark},nativeOn:{"click":function($event){return _vm.onMenuItemClick({ element: ("All " + (country.region.name)), event: ((menu.slug) + "_country_nav") })}}}):_vm._e(),_vm._l((country.competitions),function(competition,competitionIndex){return _c('MenuItem',{key:((menu.slug) + "-country-" + (competition.slug) + "-" + competitionIndex),staticClass:"sub-menu-item",attrs:{"data-test-id":("country-" + (country.region.id) + "-" + (competition.id)),"selected":competition.isSelected,"link":{ name: _vm.routeGroupName, params: { id: competition.id } },"item":{
                                    text: competition.name,
                                    control: { text: competition.eventCount, iconDisabled: true },
                                },"is-dark":_vm.isDark},nativeOn:{"click":function($event){return _vm.onMenuItemClick({ element: ("" + (competition.name)), event: ((menu.slug) + "_country_nav") })}}})})],2):_vm._e()],1)],2)]})]},{"menu":menu})],2)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }