<template>
    <div class="rules-page">
        <Spinner :listen="spinnerTriggers" class="inset" />

        <template v-if="page">
            <template v-if="!isStrapiPage && tocContent.length">
                <h2>{{ $t('seo.keywords.rules') }}</h2>
                <select v-model="selected" class="select" name="current-rule">
                    <option v-for="(item, index) of tocContent" :key="index" :value="item.id">{{ item.text }}</option>
                </select>
                <ul v-if="currentRule" class="rules-list">
                    <li v-for="subItem in currentRule.subContent" :key="subItem.id" class="rule">
                        <a :id="subItem.id + '-toc'" class="underline" :href="'#' + subItem.id">
                            {{ subItem.text }}
                        </a>
                    </li>
                </ul>
            </template>

            <template v-if="isStrapiPage">
                <TocComponent v-if="strapiTocData" :data="strapiTocData" :visible-id="selectedStrapiId" is-flat>
                    <select v-model="selectedStrapiId" class="select select-strapi" name="current-rule">
                        <option v-for="item of selectList" :key="item.id" :value="item.id">{{ item.title }}</option>
                    </select>
                </TocComponent>
            </template>

            <DynamicComponent
                v-for="(item, index) in content"
                :key="`${path}-${item.type}-${index}`"
                :data="item"
                path="content"
                :data-test-id="`tag-${item.type}`"
                data-test-class="cmsTag"
            />
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Spinner } from '@/modules/core/components';
import { action, getter } from '@/modules/platform/store/const';
import { DynamicComponent } from '@/modules/platform/components';

import SEOMixin from '@/components/Pages/SEO.mixin';
import TocComponent from '@/components/content/TocComponent';
import { getObjectField } from '@/modules/core/utils/helper';

export default {
    name: 'RulesPage',
    components: { Spinner, DynamicComponent, TocComponent },
    mixins: [SEOMixin],
    data() {
        return {
            spinnerTriggers: [action.GET_PAGE],
            path: 'rules',
            selected: null,
            selectedStrapiId: '',
        };
    },
    computed: {
        selectList() {
            return getObjectField(this.page, 'tocTree', []);
        },
        isStrapiPage() {
            return !!this.page.strapi;
        },
        strapiTocData() {
            return getObjectField(this.page, 'content', []).find(({ type }) => type === 'Toc');
        },
        strapiContent() {
            return getObjectField(this.page, 'content', []).filter(({ type }) => type !== 'Toc');
        },
        page() {
            return this.getPages[this.path] || {};
        },
        tocContent() {
            return this.page.tocContent || [];
        },
        currentRule() {
            if (this.isStrapiPage) {
                return this.selectList.find(({ id }) => id === this.selectedStrapiId);
            }
            if (!this.selected) {
                this.getSelection();
            }
            return this.tocContent.find(({ id }) => id === this.selected);
        },
        strapiNextRule() {
            const currentIndex = this.selectList.findIndex(({ id }) => id === this.selectedStrapiId);
            return this.selectList[currentIndex + 1];
        },
        content() {
            if (!this.page.content || !this.currentRule) {
                return [];
            }
            if (this.isStrapiPage) {
                let startIndex, startContentIndex, endIndex, endContentIndex;
                const newContent = JSON.parse(JSON.stringify(this.strapiContent)); // need deep clone, spread and Object.assign() not work....
                const lastRichTextIndex = newContent.lastIndexOf((i) => i.type === 'RichText');

                return newContent.map((i, componentIndex) => {
                    if (i.type === 'RichText') {
                        const isLastRichText = componentIndex === lastRichTextIndex;
                        const parsedContent = JSON.parse(i.content.content);

                        if (startIndex === undefined) {
                            const { index, contentIndex } = this.getStart(parsedContent.content, this.currentRule, componentIndex);
                            startIndex = index;
                            startContentIndex = contentIndex;
                        }

                        if (endIndex === undefined) {
                            const { index, contentIndex } = this.getEnd(
                                parsedContent.content,
                                this.strapiNextRule,
                                componentIndex,
                                isLastRichText
                            );
                            endIndex = index;
                            endContentIndex = contentIndex;
                        }

                        let start = parsedContent.content.length;
                        if (startContentIndex === componentIndex) {
                            start = startIndex;
                        } else if (isLastRichText || startContentIndex < componentIndex) {
                            start = 0;
                        }
                        const end = endContentIndex === componentIndex ? endIndex : parsedContent.content.length;

                        parsedContent.content = componentIndex > endContentIndex ? [] : parsedContent.content.slice(start, end);
                        i.content.content = JSON.stringify(parsedContent);

                        return i;
                    }
                    return i;
                });
            }

            const currentIndex = this.tocContent.findIndex((rule) => rule === this.currentRule);
            const nextRule = this.tocContent[currentIndex + 1];
            return this.page.content.slice(this.currentRule.start, nextRule ? nextRule.start - 1 : undefined);
        },
        ...mapGetters({
            getPages: getter.GET_PAGES,
        }),
    },
    watch: {
        tocContent() {
            this.getSelection();
        },
        selectList(list) {
            if (!this.selectedStrapiId && list.length) {
                this.selectedStrapiId = list[0].id;
            }
        },
    },
    created() {
        this.$store.dispatch(action.GET_PAGE, this.path).catch(() => {});
    },
    methods: {
        getSelection() {
            if (this.selected || !this.tocContent.length) {
                return;
            }
            const [{ id }] = this.tocContent;
            this.selected = id;
        },
        getStart(content, currentRule, componentIndex) {
            let contentIndex;
            const startIndex = content.findIndex(({ attrs }) => attrs.id === getObjectField(currentRule, 'id'));
            const index = startIndex >= 0 ? startIndex : undefined;

            if (startIndex >= 0) {
                contentIndex = componentIndex;
            }

            return { index, contentIndex };
        },
        getEnd(content, strapiNextRule, componentIndex, isLastRichText) {
            let endIndex;
            let contentIndex;
            if (!strapiNextRule) {
                endIndex = undefined;
            } else {
                endIndex = content.findIndex(({ attrs }) => attrs.id === strapiNextRule.id);
            }
            const index = endIndex >= 0 || isLastRichText ? endIndex : undefined;

            if (endIndex >= 0 || isLastRichText) {
                contentIndex = componentIndex;
            }

            return { index, contentIndex };
        },
    },
};
</script>

<style scoped lang="scss">
.rules-page {
    margin: 20px;
}

.rules-list {
    margin-top: 10px;
    list-style: none;
}

.rule {
    margin: 5px 0;
    color: $rules-page-rule-color;
}

.select {
    display: inline-block;
    background: $rules-page-select-background;
    border: 2px solid $rules-page-select-border-color;
    color: $rules-page-select-color;
    line-height: 1.2rem;
    vertical-align: middle;
    width: 100%;

    &-strapi {
        margin-bottom: 4px;
    }
}
</style>
