<template>
    <div v-if="paginatedGames.length && shouldShow">
        <CasinoCollectionGrid
            v-if="casinoData.type === casinoCollectionType.grid"
            :games="paginatedGames"
            :games-count="gamesCount"
            :games-limit="gamesLimit"
            :title="title"
            :icon-id="iconId"
            :is-favourites="casinoData.showOnlyFavourites"
            :show-more-games-on-bottom="casinoData.showMoreGamesOnBottom"
            :show-view-all="casinoData.showViewAll"
            :is-home-page="casinoData.isHomePage"
            :column-count="columnCount"
            @openCasinoGames="openCasinoGames"
        />
        <CasinoCollectionSlider
            v-else-if="casinoData.type === casinoCollectionType.slider"
            :games="paginatedGames"
            :games-count="gamesCount"
            :title="title"
            :icon-id="iconId"
            :is-favourites="casinoData.showOnlyFavourites"
            :show-more-games-on-bottom="casinoData.showMoreGamesOnBottom"
            :show-view-all="casinoData.showViewAll"
            :is-home-page="casinoData.isHomePage"
            :column-count="columnCount"
            @openCasinoGames="openCasinoGames"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getObjectField } from '@/modules/core/utils/helper';
import {
    fillGamesGapWithFakeGames,
    getCasinoGamesLimitForGridPerRow,
    getGamesFilterFunction,
    sortGamesByPopular,
} from '@/modules/casino/utils/CasinoCollection-utils';
import { getter as authGetter } from '@/modules/platform/store/modules/auth/const';
import { getter as platformGetter } from '@/modules/platform/store/const';
import { getter as casinoGetter, action as casinoAction } from '@/store/modules/casino/const';
import { getter as translationsGetter } from '@/store/modules/translations/const';
import { getter as coreGetter } from '@/modules/core/store/const';

import CasinoCollectionGrid from '@/modules/casino/strapiComponents/components/CasinoCollectionGrid.vue';
import CasinoCollectionSlider from '@/modules/casino/strapiComponents/components/CasinoCollectionSlider.vue';

import { casinoCollectionType } from '@/modules/casino/utils/CasinoCollection-const';
import { routeName } from '@/router/const-name';

export default {
    name: 'CasinoCollection',
    components: { CasinoCollectionGrid, CasinoCollectionSlider },
    props: {
        data: Object,
    },
    data() {
        return {
            casinoCollectionType,
        };
    },
    computed: {
        ...mapGetters({
            isAuthenticated: authGetter.IS_AUTHENTICATED,
            sideBarDisabledPages: platformGetter.GET_SIDE_BAR_DISABLED_PAGES,
            favoriteGamesIds: casinoGetter.GET_FAVORITE_GAMES_IDS,
            selectedLanguage: translationsGetter.GET_SELECTED_LANGUAGE,
            gameCategories: casinoGetter.GET_GAME_CATEGORIES,
            games: casinoGetter.GET_GAMES,
            isLoading: coreGetter.IS_LOADING,
        }),
        title() {
            if (this.casinoData.showOnlyFavourites) {
                return this.$t(`ui.casinoLobby.favourites`);
            }
            if (this.casinoData.title) {
                return this.casinoData.title;
            }
            if (this.categoryData) {
                return this.categoryData.text;
            }
            return this.$t(`ui.eventPage.category.all`);
        },
        iconId() {
            if (this.casinoData.iconId) {
                return this.casinoData.iconId;
            }
            if (this.casinoData.showOnlyFavourites) {
                return 'icon-star-tab';
            }
            if (this.categoryData) {
                return this.categoryData.iconId;
            }
            return null;
        },
        casinoData() {
            let {
                title,
                iconId,
                type,
                showMoreGamesOnBottom,
                showViewAll,
                sliderNumberOfSlides,
                showOnlyFavourites,
                gridNumberOfRows,
                isHomePage,
                columnsByBreakpoints,
            } = this.data.content;

            return {
                title,
                iconId,
                type,
                showMoreGamesOnBottom: showMoreGamesOnBottom ?? false,
                showViewAll: showViewAll ?? false,
                sliderNumberOfSlides: sliderNumberOfSlides ?? 4,
                gridNumberOfRows: gridNumberOfRows ?? 2,
                showOnlyFavourites: showOnlyFavourites ?? false,
                isHomePage: isHomePage ?? false,
                columnsByBreakpoints,
            };
        },
        shouldShow() {
            if (this.casinoData.showOnlyFavourites) return this.isAuthenticated;
            return !!this.categoryData;
        },
        paginatedGames() {
            return this.filteredGames.slice(0, this.gamesLimit);
        },
        filteredGames() {
            if (!this.games.data) return fillGamesGapWithFakeGames([], this.gamesLimit);
            const filterFn = getGamesFilterFunction(
                this.casinoData.showOnlyFavourites,
                this.favoriteGamesIds,
                getObjectField(this.categoryData, 'id')
            );
            return this.games.data.filter(filterFn).sort(sortGamesByPopular);
        },
        gamesCount() {
            return this.casinoData.showOnlyFavourites ? this.favoriteGamesIds.length : this.filteredGames.length;
        },
        gamesLimit() {
            if (this.casinoData.type === casinoCollectionType.slider) {
                return this.columnCount * this.casinoData.sliderNumberOfSlides;
            }
            return this.columnCount * this.casinoData.gridNumberOfRows;
        },
        categoryData() {
            let categoryId = getObjectField(this.data.content, 'category', '');
            return this.gameCategories.find(({ id }) => id === categoryId);
        },
        isRightSideBarHidden() {
            return this.sideBarDisabledPages.includes(this.$route.path);
        },
        columnCount() {
            return getCasinoGamesLimitForGridPerRow(this.$mq, this.isRightSideBarHidden, this.casinoData.columnsByBreakpoints);
        },
    },
    mounted() {
        if (!this.isLoading(casinoAction.GET_CASINO_GAMES_NEW)) {
            this.$store.dispatch(casinoAction.GET_CASINO_GAMES_NEW);
        }
    },
    methods: {
        openCasinoGames() {
            let query = {
                category: this.categoryData ? `${this.categoryData.id}` : undefined,
            };
            if (this.casinoData.showOnlyFavourites) {
                query = { favourites: 'true' };
            }
            if (this.casinoData.showViewAll) {
                query = null;
            }
            this.$router.push({
                name: routeName.CASINO,
                query,
            });
        },
    },
};
</script>
